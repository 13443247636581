*,
::before,
::after {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}
table td {
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
}

table.with-ellipsis td {
  text-overflow: ellipsis;
}
